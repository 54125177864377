import {
  ComposableMap,
  Geographies,
  Geography,
  Graticule,
  Sphere,
} from "react-simple-maps";
import React, { useEffect, useState } from "react";
import { csv } from "d3-fetch";

const map_features = "/files/map_features.json";

export default function WorldChart() {
  const [data, setData] = useState([]);

  useEffect(() => {
    csv("/files/color_map.csv").then((data) => {
      setData(data);
      // console.log("data", data);
    });
  }, []);
  return (
    <>
      <ComposableMap
        projectionConfig={{
          rotate: [-10, 0, 0],
          scale: 147,
        }}
      >
        <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
        <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
        {data.length > 0 && (
          <Geographies geography={map_features}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const d = data.find((s) => s.ISO3 === geo.id);
                // console.log("geo.id", geo.id);
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={
                      d
                        ? d["Participated"] === "1"
                          ? "#3b82f6"
                          : "#000"
                        : "000"
                    }
                  />
                );
              })
            }
          </Geographies>
        )}
      </ComposableMap>
      <p className="italic text-sm text-center">
        The blue countries are where IBB participants from all over the world
        have come from!
      </p>
    </>
  );
}
