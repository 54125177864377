import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/SEO";
import Hero from "../components/hero";
import MapChart from "../components/MapChart";
import { useStaticQuery, graphql } from "gatsby";
import Banner from "../components/banner";
import CompsData from "../data/comps.json";
import { StaticImage } from "gatsby-plugin-image";

const CompetitionPage = () => {
  const { heroImage, site } = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "competition.jpg" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          email
          registerLink
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Competition"
        description="The International Biology Bowl is a competition with two stages: the Open Round and the Semifinal Round."
      />
      <Hero image={heroImage} title="Competition" />
      <div className="max-w-screen-md mx-auto py-10 px-4 sm:px-6 block pt-20 text-lg">
        <div className="mb-16">
          <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-5xl mb-4 sm:mb-6">
            Introduction
          </h1>
          <p className="text-gray-500 mb-8">
            The International Biology Bowl is open to all high school students
            around the world. The competition has two parts: an Open Round and a
            Semifinal Round. All students are invited to form teams of 2-4
            members and participate in the 120 minute, 100 question written test
            (Open Round). Out of all teams, a select number of teams that
            performed the best on the first exam proceed to the buzzer-style
            Semifinal Round. Before the main IBB competition, however, we host
            several regional competitions where people can compete with other
            biology students in their area.
          </p>
          <a
            href="https://docs.google.com/document/d/1CW4fOMTvCons2JCsKx0PhW-TYXkd169PfPRDPQKLxis/edit?usp=sharing"
            target="_blank"
            className="bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 px-5 py-3 border border-transparent text-base font-medium rounded-md text-white transition duration-150 ease-in-out"
          >
            Rules and Logistics
          </a>
        </div>
        <div className="mb-16">
          <MapChart />
        </div>
        {/* <div className='mb-16'>
          <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-5xl mb-4 sm:mb-6">
            Prizes
          </h1>
          <div className='text-gray-500'>
            <p className='mb-4 font-bold'>
              1<sup>st</sup> Place: $100 Visa gift card + $25 AoPS Coupon <br />
              2<sup>nd</sup> Place: $60 Amazon gift card + $25 AoPS Coupon <br />
              3<sup>rd</sup> Place: $25 AoPS Coupon + $10 Panera Bread gift card <br />
              4<sup>th</sup> Place: $25 AoPS Coupon <br />
            </p>
            <p>
              Additionally, everyone who registers for the competition can enter a raffle for a
              <strong> free copy of a biology textbook of the winner's choice</strong>, awarded by <a href="https://biolympiads.com" target="_blank" className='underline'>Biolympiads</a>! 
              To enter, fill out <a href="https://forms.gle/sXBBBwMqRExD2dqq5" target="_blank" className='underline'>this form</a>.
            </p>
          </div>
        </div> */}
        <div className="mb-16">
          <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-5xl mb-4 sm:mb-6">
            International Competition
          </h1>
          <p className="text-gray-500 mb-4">
            The IBB International Competition will consist of two rounds: the
            IBB Open (Exam) and IBB Semifinals (Bowl). The Top 4 from each
            regional competition will receive direct qualification to IBB
            Semifinals. For everyone else, IBB Opens will be your ticket to
            qualification. The Top 24 teams, excluding direct qualifiers, from
            IBB Opens will qualify for Semifinals. Our International competition
            invites the most number of participants, from all around the world,
            competing for $500 USD in prizes. The IBB Opens will be a timed and
            proctored exam, administered and available during a 24-hour window.
            IBB Semifinals will commence two weeks after Opens and will start
            and finish on the same day.
          </p>
          <p className="text-gray-500 font-bold text-xl mb-4">
            Current Date: TBD
          </p>
          <div className="shadow-md rounded p-4 text-sm bg-gray-100">
            <p className="font-bold text-xl mb-2">International Biology Bowl</p>
            {/* <p className='mb-1'><span className='font-semibold'>Sign-Up Form:</span> <a className='text-blue-600 hover:underline' href={site.siteMetadata.registerLink}>{site.siteMetadata.registerLink}</a></p> */}
            <p className="mb-1">
              <span className="font-semibold">Discord Server:</span>{" "}
              <a
                className="text-blue-600 hover:underline"
                href="https://discord.gg/sFETzRbjMR"
              >
                https://discord.gg/sFETzRbjMR
              </a>
            </p>
          </div>
        </div>
        <div className="mb-16">
          <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-5xl mb-4 sm:mb-6">
            Regional Competitions
          </h1>
          <p className="text-gray-500 mb-4">
            IBB regional competitions are open to people within a certain region
            or located closely to it. All regional competitions occur on the
            same day and are held before the international competition. The
            top-performing teams at each regional competition will automatically
            qualify for the International Biology Bowl's Semifinal Round! If you
            do not strictly fall under any of these regions, please register for
            the competition closest to your location (ex. if you live in Africa,
            sign up for Asian Biology Bowl).
          </p>
          <div className="p-4 mb-4 text-[#4e4e4e] rounded-lg bg-[#daeaff] text-sm">
            Participants in Asia and the Middle East should sign up for ABB,
            while USABB is exclusive for participants in North and South America
          </div>
          {/* <p className="text-gray-500 font-bold text-xl mb-4">
            Current Date: November 2-3, 2024
          </p> */}
          <div className="grid grid-cols-1 gap-6">
            {CompsData.map((comp) => {
              return (
                <>
                  <div className="shadow-md rounded p-4 text-sm bg-gray-100">
                    <p className="font-bold text-xl mb-1">
                      {comp.region} Biology Bowl
                    </p>
                    <p className="mb-1">
                      <span className="font-semibold">Open Round: </span>
                      November 2-3, 2024
                    </p>
                    <p className="mb-1">
                      <span className="font-semibold">Sign-Up Form:</span>{" "}
                      <a
                        className="text-blue-600 hover:underline"
                        href={comp.signup}
                      >
                        {comp.signup === "#" ? "TBA" : comp.signup}
                      </a>
                    </p>
                    <p className="mb-1">
                      <span className="font-semibold">Discord Server:</span>{" "}
                      <a
                        className="text-blue-600 hover:underline"
                        href={comp.discord}
                      >
                        {comp.discord}
                      </a>
                    </p>
                    {comp.areas && (
                      <p className="mb-1">
                        <span className="font-semibold">Areas Covered:</span>{" "}
                        {comp.areas}
                      </p>
                    )}
                  </div>
                  {
                    comp.region == "Western" &&
                      null /* <StaticImage className="rounded" src="../images/comp_regions.png" alt="Competition Regions" /> */
                  }
                </>
              );
            })}
          </div>
        </div>
        <div className="mb-16">
          <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-5xl mb-4 sm:mb-6">
            Open Round
          </h1>
          <div className="text-gray-500">
            <p className="mb-4">
              The Open round for regional tournaments and international
              tournaments will have a 90-question multiple choice/multi-select
              exam. Both of these open rounds have time limits of 120 minutes,
              made to be completed by <strong>teams of 2-4</strong>; people
              could still do excellent on this test alone, however. There will
              be a specific time period in which your team can start doing the
              exam at any time, but you must submit the exam by the deadline.
              Difficulty of the Open Round questions range from high school
              biology up to{" "}
              <a
                href="https://www.usabo-trc.org/src-content-information"
                target="_blank"
              >
                USA Biolympiad Semifinals Material
              </a>{" "}
              material.
            </p>
            <p className="mb-1">
              The question breakdown for this test is as follows:
            </p>
            <ul className="list-disc list-inside font-bold mb-4 list">
              <li>25% - Human Anatomy and Physiology</li>
              <li>25% - Cell Biology & Biochemistry</li>
              <li>20% - Genetics</li>
              <li>15% - Botany</li>
              <li>10% - Ecology & Ethology</li>
              {/* <li>5% - Biostatistics</li> */}
              <li>5% - Biosystematics and Bioinformatics</li>
            </ul>
            <p className="mb-4">
              This test is split into 6 sections (one for each of the above
              topics). As you progress in a section, the problems will also
              increase in difficulty. The amount of points each problem is worth
              will also increase as you near the end of a section. For example,
              the first few questions of each section may be worth 1 point, but
              the questions close to the end of the test may be worth up to 4
              points. Each question will have five choices, some needing
              multiple answers.
            </p>
            <p>
              The top 32 teams of the USABB will move onto the USABB finals,
              while the top 32 teams of the ABB will move onto the ABB
              semifinals.
            </p>
          </div>
        </div>
        <div className="mb-16">
          <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-5xl mb-4 sm:mb-6">
            Semifinal Round
          </h1>
          <div className="text-gray-500 pb-4">
            <p>
              The top 32 teams from the Open Round are able to attend the
              Semifinal Round (if any of the top 32 teams cannot attend, the
              teams with the next-highest scores will be asked to replace them).
              This is a science bowl-style tournament, with a 25-question tossup
              packet used for each level of the bracket. There will also be 25
              bonus questions (1 per tossup) in the packet for the final round.
              The teams will follow a classic 32-competitor tournament bracket.
              Prizes will be issued to the winners after the competition ends.
            </p>
          </div>
        </div>
        <div>
          <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-5xl mb-4 sm:mb-6">
            Official Information/Rules
          </h1>
          <div className="text-gray-500 pb-4">
            <p className="mb-8">
              The Official IBB Information and Rules Document below should serve
              to provide you with a detailed overview of the organization,
              competition structure, and rules for all current and future IBB
              tournaments. If you have any questions not answered in the
              document, please reach out to{" "}
              <a
                target="_blank"
                className="text-blue-600 hover:underline"
                href="mailto:internationalbiologybowl@gmail.com"
              >
                internationalbiologybowl@gmail.com
              </a>
              .
            </p>
            <a
              href="https://docs.google.com/document/d/1CW4fOMTvCons2JCsKx0PhW-TYXkd169PfPRDPQKLxis/edit?usp=sharing"
              target="_blank"
              className="bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 px-5 py-3 border border-transparent text-base font-medium rounded-md text-white transition duration-150 ease-in-out"
            >
              Rules and Logistics
            </a>
          </div>
        </div>
      </div>
      <Banner />
    </Layout>
  );
};

export default CompetitionPage;
